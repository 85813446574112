// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./choose_subscription.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="choose_subscription" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h3 t="profile.subscription.title"></h3>
    </div>
    <div class="background-darker flex-column">
      <div class="yearly">
        <div class="flex-row">
          <!-- <div class="title_container" click.delegate="setType('abonnemment')" class.bind="isActive == 1 ? 'active' : ''">
            <span t="home.welcome.pricing.monthly.title" class="title"></span>
          </div> -->
          <div class="title_container" click.delegate="setType('abonnemment++')" class.bind="isActive == 2 ? 'active' : ''">
            <span t="home.welcome.pricing.yearly.title" class="title"></span>
          </div>
        </div>
        <hr>
        <ul>
          <li t="home.welcome.pricing.yearly.pricing_one_bank">Infinite European Accounts</li>
          <li t="home.welcome.pricing.yearly.pricing_one_wallet">See balances & transactions</li>
          <li t="home.welcome.pricing.yearly.pricing_month_list1">Initiate payments</li>
          <li t="home.welcome.pricing.yearly.pricing_month_list2">Infinite BSV Wallet</li>
          <li t="home.welcome.pricing.yearly.pricing_month_list3">See balances & transactions</li>
          <li t="home.welcome.pricing.yearly.topup2">Initiate payments</li>
        </ul>
        <hr class="bottom">
        <div class="flex-row pricing">
          <!-- <span class="price" if.bind="isActive == 1">4</span> -->
          <span class="price" if.bind="isActive == 2">29</span> <!--2.42/month-->
          <span class="euro">€</span>
          <!-- <span class="time" t="home.welcome.pricing.monthly.rate" if.bind="isActive == 1">/month</span> -->
          <span class="time" t="home.welcome.pricing.yearly.rate" if.bind="isActive == 2">/year</span>
          <!-- <div class="flex-grow" if.bind="isActive == 2"></div>
          <span class="percent" if.bind="isActive == 2">-39%</span> -->
        </div>
      </div>
      <button class="btn btn-primary fakeDisabled" if.bind="!type" click.delegate="shakeError()">
        <span t="profile.subscription.choose_subscription9" if.bind="!isLoading"></span>
      </button>
      <button class="btn btn-primary" if.bind="type" click.delegate="initiateSubscription()">
        <span t="profile.subscription.choose_subscription9" if.bind="!isLoading"></span>
        <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
      </button>
    </div>
  </section>
</template>
`;
// Exports
export default code;