import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-dependency-injection';

@autoinject
export class subscriptionFailure {
    constructor(private router: Router) { }

    goToHome() {
        this.router.navigateToRoute('home')
    }
}
