import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { HttpClient } from "aurelia-fetch-client";
import { BankaService } from "services/banka.service";
import { SingletonService } from "singleton";
import { User } from "components/models/UserModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { connectTo, Store } from "aurelia-store";
import { State } from "state";

type SubscriptionTypes = "abonnemment" | "abonnemment++";
@connectTo()
@autoinject()
export class login {
  private me: User;
  private type: SubscriptionTypes = "abonnemment";
  private isLoading: boolean = false;
  private shaking: boolean;
  private payerName: string;
  private hasAccount: boolean = false;
  private totalAccountsCount: number;
  private banks: Array<YapilyBankModel>;
  private iban: string;
  private isActive: number = 2;

  constructor(
    private router: Router,
    private authService: AuthService,
    private bankaService: BankaService,
    private singleton: SingletonService,
    private store: Store<State>,
    private state: State
  ) {}

  activate() {
    this.getMe();
    //this.loadCurrentAccount();
  }

  async getMe() {
    this.me = await this.authService.getMe();
    this.singleton.setPayerName(this.me.displayName);
    this.payerName = this.singleton.getPayerName();
  }

  setType(type: SubscriptionTypes) {
    this.type = type;

    switch (this.type) {
      case "abonnemment":
        this.isActive = 1;
        break;
      case "abonnemment++":
        this.isActive = 2;
        break;
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  async initiateSubscription() {
    try {
      try {
        this.isLoading = true;

        const account = this.checkBankAccount();
        let iban = account.identification;
        let currency = account.currency
        const institution = account.institutionId;
        this.type = "abonnemment++"
        const data = await this.bankaService.initiatSubscription(
          institution,
          this.type,
          iban,
          currency
        );
        localStorage.setItem("payer_name_subscription",this.me.displayName);
        localStorage.setItem("iban_subscription",iban);
        localStorage.setItem("paymentIdempotencyId_subscription",data[0].paymentIdempotencyId);
        
        if (!data[1].data.authorisationUrl) {
          alert("Please Try Again");
          this.isLoading = false;
        }
        this.singleton.setPayerName(this.me.displayName);
        window.location.replace(data[1].data.authorisationUrl);
      } catch (e) {
        if (this.me.role == "admin") {
          alert(e);
          console.log(e)
        } else this.router.navigateToRoute("add_account");
        return;
      }
    } catch (error) {
      alert("Sorry there was a problem on our part try again later");
      this.isLoading = false;
    }
  }

  /* async loadCurrentAccount() {
    this.totalAccountsCount = 0;
    await this.loadBanks();
  }

  async loadBanks() {
    try {
      const update = await this.bankaService.getPopulatedBanks();
      this.setBanks(update);
    } catch (e) {}
  }
  setBanks(banks: YapilyBankModel[], cacheIt = true) {
    if (banks) {
      this.totalAccountsCount = 0;
      for (let bank of banks) {
        this.totalAccountsCount += bank.accounts.length;
      }

      this.hasAccount = banks.length >= 1;

      if (cacheIt) {
        this.singleton.setMyBanks(banks);
      }
      this.banks = banks;
    } else {
      this.hasAccount = false;
      this.totalAccountsCount = 0;
    }
  } */

  checkBankAccount() {
    const selectedAccount = this.state.wallets[this.state.selectedAccountIndex];
    
    if (!selectedAccount.isBlockchain) {
      console.log("ici bank:" + JSON.stringify(selectedAccount));
      return selectedAccount;
    }
    const account = this.state.wallets.find(wallet => !wallet.isBlockchain);
  
    if (!account) {
      this.router.navigateToRoute("add_account")
    }
  
    return account;
  }
}
