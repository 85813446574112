// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/x_circle.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./failure.scss"></require>
  <section id="subscription_failure" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="goToHome()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1>Oops</h1>
    </div>
    <div class="flex-grow"></div>
    <img class="red_circle" src="${___HTML_LOADER_IMPORT_1___}">
    <div class="flex-grow"></div>
    <p i18n="profile.subscription_failure2"></p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToHome()" i18n="goBackHome">Retourner à l'accueil</button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;